import { config } from './config';

export class Slides {
  slides = document.querySelectorAll('.js-slide');
  controls = document.querySelectorAll('.js-control');

  loading = document.getElementById('loading');
  aside = document.getElementById('aside');
  wrapper = document.getElementById('main');
  bg = document.getElementById('slidesBg');

  prevButton = document.getElementById('prev');
  nextButton = document.getElementById('next');

  step = document.getElementById('step');
  stepIndex = document.getElementById('stepIndex');

  currentSlide = 0;

  touchstartX = 0;
  touchendX = 0;

  isDomLoaded = false;
  countdownPassed = false;

  constructor() {
    document.addEventListener('DOMContentLoaded', () => {
      this.isDomLoaded = true;
      this.onUpdate();
    });

    setTimeout(() => {
      this.countdownPassed = true;
      this.onUpdate();
    }, config.loadingTotal);
  }

  onUpdate() {
    if (this.countdownPassed && this.isDomLoaded) {
      this.init();
    }
  }

  init() {
    this.initControls();
    this.loading.style.opacity = '0';

    setTimeout(() => {
      this.loading.style.visibility = 'hidden';
      this.loading.style.display = 'none';
    }, config.loadingFirst);

    setTimeout(() => {
      this.initialRender();
    }, config.bgTransition);
  }

  showSlide(index: number) {
    if (index < 0 || index > this.slides.length) {
      return;
    }

    this.currentSlide = index;
    this.render();
  }

  initControls() {
    this.prevButton.addEventListener('click', () => {
      this.showSlide(this.currentSlide - 1);
    });

    this.nextButton.addEventListener('click', () => {
      this.showSlide(this.currentSlide + 1);
    });

    this.controls.forEach((control) => {
      control.addEventListener('click', () => {
        this.showSlide(parseInt(control.getAttribute('data-index')));
      });
    });

    this.wrapper.addEventListener('touchstart', (e) => {
      this.touchstartX = e.changedTouches[0].screenX;
    });

    this.wrapper.addEventListener('touchend', (e) => {
      this.touchendX = e.changedTouches[0].screenX;
      this.handleGesture();
    });

    window.addEventListener('resize', () => {
      this.render();
    });
  }

  handleGesture() {
    if (this.touchendX < this.touchstartX) {
      this.onSwipeLeft();
    }

    if (this.touchendX > this.touchstartX) {
      this.onSwipeRight();
    }
  }

  onSwipeRight() {
    this.showSlide(this.currentSlide - 1);
  }

  onSwipeLeft() {
    this.showSlide(this.currentSlide + 1);
  }

  initialRender() {
    this.hideSlides();
    this.hideStep();
    this.hideActiveControl();

    this.renderPrevArrow();
    this.renderNextArrow();

    this.renderBg();
    this.wrapper.style.opacity = '1';

    this.showActiveControl();
    this.showActiveSlide();
    this.showStep();
  }

  render() {
    this.hideSlides();
    this.hideStep();
    this.hideActiveControl();

    this.renderPrevArrow();
    this.renderNextArrow();
    this.renderAside();

    setTimeout(() => {
      this.renderBg();

      setTimeout(() => {
        this.showActiveControl();
        this.showActiveSlide();
        this.showStep();
      }, config.bgTransition);
    }, config.coreTransition);
  }

  renderPrevArrow() {
    if (this.currentSlide === 0) {
      this.prevButton.classList.add('hidden');
    } else {
      this.prevButton.classList.remove('hidden');
    }
  }

  renderNextArrow() {
    if (this.currentSlide >= this.slides.length) {
      this.nextButton.classList.add('hidden');
    } else {
      this.nextButton.classList.remove('hidden');
    }
  }

  hideSlides() {
    this.slides.forEach((slide) => {
      slide.classList.remove('shown');
    });
  }

  showActiveSlide() {
    if (this.currentSlide >= this.slides.length) {
      return;
    }

    this.slides[this.currentSlide].classList.add('shown');
  }

  hideStep() {
    this.step.classList.add('hidden');
  }

  showStep() {
    if (this.currentSlide === 0 || this.currentSlide === this.slides.length) {
      return;
    }

    this.stepIndex.innerHTML = `${this.currentSlide} `;
    this.step.classList.remove('hidden');
  }

  hideActiveControl() {
    this.controls.forEach((control) => {
      control.classList.remove('active');
    });
  }

  showActiveControl() {
    this.controls[this.currentSlide].classList.add('active');
  }

  renderAside() {
    if (this.currentSlide === this.slides.length) {
      setTimeout(() => {
        this.aside.classList.add('aside--shown');
      }, config.bgTransition);
    } else {
      this.aside.classList.remove('aside--shown');
    }
  }

  renderBg() {
    const wW = window.innerWidth;

    const iW = this.bg.clientWidth;
    const iH = this.bg.clientHeight;

    const sT = this.slides.length;
    const sC = this.currentSlide;

    const shift = -1 * ((iW - 0.2 * wW) / sT) * sC;

    this.bg.style.transform = `translateX(${shift}px)`;
  }
}
