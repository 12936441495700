export const config = {
  coreTransition: 300,

  bgTransition: 500,
  bgWidth: 12226,
  bgHeight: 1080,

  loadingFirst: 1000,
  loadingSecond: 2000,
  loadingTotal: 4000,
};
