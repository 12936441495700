import { config } from './config';

export class Loading {
  loadingTitle: HTMLElement = document.getElementById('loadingTitle');
  loadingSuffix: HTMLElement = document.getElementById('loadingSuffix');
  loadingMessage: HTMLElement = document.getElementById('loadingMessage');

  constructor() {
    this.showInitialText();
  }

  showInitialText() {
    setTimeout(() => {
      this.loadingTitle.classList.add('loading__title--shown');
      this.showAdditionalText();
    }, config.loadingFirst);
  }

  showAdditionalText() {
    setTimeout(() => {
      this.loadingSuffix.classList.add('loading__suffix--hidden');
      this.loadingMessage.classList.add('loading__message--shown');
    }, config.loadingSecond);
  }
}
